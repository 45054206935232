<template>
	<div class="ncs-popup">
		<div class="close">
			<img src="/images/close.png" alt=""  @click="$emit('close')">
		</div>

		<div class="show-box">
			<div class="tit">{{ncsClsfInfo.abilUnitNm}}</div>
			<div class="Board type3">
				<table class="Board_type3" style="border-top:hidden;">
					<colgroup>
						<col width="30%">
						<col width="60%">
						<col width="9%">
					</colgroup>
					<thead>
						<tr>
							<th>진단영역</th>
							<th>진단문항</th>
							<th>평가</th>
						</tr>
					</thead>
					<tbody>
						<tr v-for="row in ncsSelfEvalQstList" :key="row.selfDiagQeusSeq">
							<td v-if="row.mergeChkCnt == row.mergeCnt" :rowspan="row.mergeCnt" class="score">{{row.abilUnitElemNm}}</td>
							<td>{{row.qeusCont}}</td>
							<td class="score" v-if="row.chk == '1'">매우미흡</td>
							<td class="score" v-else-if="row.chk == '2'">미흡</td>
							<td class="score" v-else-if="row.chk == '3'">보통</td>
							<td class="score" v-else-if="row.chk == '4'">우수</td>
							<td class="score" v-else>매우우수</td>
						</tr>
					</tbody>
				</table>
			</div>
		</div>
	</div>
</template>

<script>
export default {
	props: ['param'],
	data() {
		return {
			forwardData : this.param,
			ncsClsfInfo : {},
			ncsSelfEvalQstList : [],
		}
	},

	beforeMount() {
		// console.log('forwardData param :', this.param);
		this.selectInitData();
	},

	methods : {
		selectInitData() {
			this.$.httpPost('/api/mem/prf/selectNcsSelfEvalQst', this.forwardData, '')
				.then(res => {
					// console.log('RESULT :', res);

					if(res.data.ncsClsfInfo) this.ncsClsfInfo = res.data.ncsClsfInfo;
					if(res.data.ncsSelfEvalQstList) this.ncsSelfEvalQstList = res.data.ncsSelfEvalQstList;

					this.$emit('updated');
				})
				.catch(err => {
					// console.log('============================='); 
					// console.log(err);
					// console.log(err.response);
					// console.log('============================='); 

					alert(err.response.data.error_description);
				})
		}
	}
}
</script>